<template>
    <v-container fill-height>
        <div style="width:100%" class="d-flex align-center flex-column justify-center">
            <v-img max-width="300" src="@/assets/Nuvolos-transparent.svg" />
            <span class="primary--text title text-center font-weight-bold primary--text mt-10">Thank you for requesting a trial!</span>
            <span class="primary--text text-center">We'll get in touch with you shortly to arrange your trial.</span>
            <v-btn text-center text outlined class="primary--text mt-3" :to="{ name: 'welcome' }">Back to main page</v-btn>
        </div>
    </v-container>
</template>

<script>
export default {
    data() {
        return {}
    }
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { attrs: { "fill-height": "" } }, [
    _c(
      "div",
      {
        staticClass: "d-flex align-center flex-column justify-center",
        staticStyle: { width: "100%" }
      },
      [
        _c("v-img", {
          attrs: {
            "max-width": "300",
            src: require("@/assets/Nuvolos-transparent.svg")
          }
        }),
        _c(
          "span",
          {
            staticClass:
              "primary--text title text-center font-weight-bold primary--text mt-10"
          },
          [_vm._v("Thank you for requesting a trial!")]
        ),
        _c("span", { staticClass: "primary--text text-center" }, [
          _vm._v("We'll get in touch with you shortly to arrange your trial.")
        ]),
        _c(
          "v-btn",
          {
            staticClass: "primary--text mt-3",
            attrs: {
              "text-center": "",
              text: "",
              outlined: "",
              to: { name: "welcome" }
            }
          },
          [_vm._v("Back to main page")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }